<script setup>
  import { useHead } from '@unhead/vue';

  useHead({
    title: () => 'Home Page Custom Title',
    meta: [
      {
        name: 'description',
        content: 'Home page description',
      },
      {
        property: 'og:image',
        content: 'https://esigns-og.workr.in/home.png',
      },
      {
        property: 'og:url',
        content: 'https://esigns-og.workr.in',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:site_name',
        content: 'eSigns',
      },
    ],
  })
</script>

<template>
  <h1>Home Page</h1>
</template>

