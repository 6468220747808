<script setup>
  import { useHead } from '@unhead/vue';
  
  useHead({
    title: () => 'Contact Page Custom Title',
    meta: [
      {
        name: 'description',
        content: 'Contact page description',
      },
      {
        property: 'og:image',
        content: 'https://esigns-og.workr.in/contact.jpeg',
      },
      {
        property: 'og:url',
        content: 'https://esigns-og.workr.in/es-contact',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:site_name',
        content: 'eSigns',
      },
    ],
  })
</script>

<template>
  <h1>Contact Page</h1>
</template>