import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import { InferSeoMetaPlugin } from '@unhead/addons'
import router from './router';

import App from './App.vue'

const app = createApp(App);

const head = createHead({
  plugins: [
    InferSeoMetaPlugin()
  ]
})
app.use(head)
app.use(router);

app.mount('#app')
