<script setup>
  import { useHead } from '@unhead/vue';

  useHead({
    title: () => 'Features Page Custom Title',
    meta: [
      {
        name: 'description',
        content: 'Features page description',
      },
      {
        property: 'og:image',
        content: 'https://esigns-og.workr.in/features.jpeg',
      },
      {
        property: 'og:url',
        content: 'https://esigns-og.workr.in/es-features',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:site_name',
        content: 'eSigns',
      },
    ],
  })
</script>

<template>
  <h1>Features Page</h1>
</template>